import GetInTouch from 'components/GetInTouch'
import { graphql } from 'gatsby'
import { getSeoImage } from 'utils/googleData'

export default GetInTouch

export const query = graphql`
query getGetInTouchPageQuery($language: String!) {
  pageData: allMdx(filter: {fields: {slug: {eq: "/book-a-meeting"}}}) {
    nodes {
      body
      id
      internal {
        contentFilePath
      }
      headings {
        depth
        value
      }
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        description
        showCTA
        seoTitle
        seoDescription
        seoimage
        demoCalenderUrl
        qaCalenderUrl
        demoCalenderTitle
        qaCalenderTitle
      }
      parent {
        ... on GoogleDocs {
          id
          images {
            name
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          images2: images {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(width: 265, height: 265)
            }
          }
        }
      }
    }
  }
  locales: allLocale(
    filter: {language: {eq: $language}, ns: {in: ["book-a-meeting", "common"]}}
  ) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}

`
